<script>
export default {
  name: 'Tabs',
  props: {
    dark: {
      type: Boolean,
      default: false
    },

    links: {
      type: Array,
      default: () => []
    },

    indexActive: {
      type: Number,
      default: null
    },

    useAlias: {
      type: Boolean,
      default: false
    },

    aliasActive: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      isMounted: false,
      scrollPosition: {
        start: true,
        end: false
      }
    }
  },

  computed: {
    hasScroll () {
      return this.isMounted && this.$refs.tabs.scrollWidth > this.$refs.tabs.offsetWidth
    },

    activeLinks () {
      return this.links.filter(item => !item.disabled)
    }
  },

  mounted () {
    this.isMounted = true

    this.hasScroll && this.$nextTick(() => {
      const link = this.$refs.tablink.find(item => item.$el.className.indexOf('is-active') >= 0)

      if (link) {
        const offset = link.$el.offsetLeft

        this.$refs.tabs.scrollLeft = offset - 20
      }
    })
  },

  methods: {
    openTab (index) {
      this.$emit('tabChange', index)
    },

    checkScroll (event, type) {
      event.preventDefault()

      if (type === 'wheel') {
        this.$refs.tabs.scrollLeft = this.$refs.tabs.scrollLeft + event.deltaY
      }

      if (this.$refs.tabs.scrollLeft === 0) {
        this.scrollPosition.start = true
        this.scrollPosition.end = false
      } else if (this.$refs.tabs.scrollWidth - this.$refs.tabs.scrollLeft === this.$refs.tabs.offsetWidth) {
        this.scrollPosition.start = false
        this.scrollPosition.end = true
      } else {
        this.scrollPosition.start = false
        this.scrollPosition.end = false
      }
    }
  }
}
</script>

<template>
  <div
    ref="tabs"
    class="tabs center"
    :class="{
      'theme-dark': dark,
      'has-scroll': hasScroll,
      'scroll-start': hasScroll && scrollPosition.start,
      'scroll-end': hasScroll && scrollPosition.end
    }"
    @mousewheel.stop="hasScroll && checkScroll($event, 'wheel')"
    @scroll="hasScroll && checkScroll($event, 'scroll')"
  >
    <span
      v-for="(item, index) in activeLinks"
      :key="index"
    >
      <RouterLink
        v-if="item.location"
        ref="tablink"
        :key="index"
        class="tabs-link"
        :to="item.location"
      >
        <span class="text">{{ $t(item.text) }}</span>
      </RouterLink>

      <a
        v-else
        href="#"
        class="tabs-link"
        :class="{'is-active': useAlias ? item.alias === aliasActive : index === indexActive}"
        @click.prevent="openTab(useAlias ? item.alias : index)"
      >
        <span class="text">{{ $t(item.text) }}</span>
      </a>
    </span>
  </div>
</template>

<style src="@/assets/styles/themes/default/tabs.css"></style>
